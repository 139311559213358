export enum NotificationContexts {
    SERVICE_WORKER = "SERVICE_WORKER",
    STALE_SESSION = "STALE_SESSION",
}

export enum BroadcastMessageTypes {
    MFE_RELOAD_PAGE = "MFE_RELOAD_PAGE",
    MFE_CLOSE_INSTALL_NOTIFICATION = "MFE_CLOSE_INSTALL_NOTIFICATION",
    MFE_ADD_INSTALL_NOTIFICATION = "MFE_ADD_INSTALL_NOTIFICATION",
}

export interface BroadcastMessageEvent {
    type: BroadcastMessageTypes;
    context: NotificationContexts;
}

export type NotificationInteraction = (options: { context: NotificationContexts }) => void;

export type AddNotification = (options: { context: NotificationContexts }) => void;

export type RemoveNotification = () => void;

export type ApplicationNotification = {
    add: AddNotification;
    remove: RemoveNotification;
};

/**
 * A module to add/remove a notification that informs that user of the following
 * "update" scenarios:
 *
 * 1. A new Service Worker is "waiting" to be installed.
 *
 * 2. A user's session has "lingered" for too long, is stale and needs refreshing.
 *
 * Let's look at each scenario in more depth...
 *
 * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 *
 * 1. Update Service Worker:
 * =========================
 *
 * Why:
 * ----
 * Updating a Service Worker is problematic, with the "best-practice" implementation
 * being to ask the user to confirm this "aggressive" action.
 *
 * How:
 * ----
 * Below is the sequence to update a Service Worker:
 *
 * 1. A new Service Worker that cannot be activated immediately triggers a "waiting"
 *    event.
 *
 * 2. The Notification is added to the DOM, informing users that a new Service
 *    Worker is ready to install.
 *
 * 3. The user clicks "update" which posts a "SKIP_WAITING" message to the Service
 *    Worker telling it to "force-install" the new version (even if existing
 *    application sessions are running).
 *
 * 4. The Service Worker installs and triggers a "controlling" event to the
 *    "active" tab.
 *
 * 5. The "active" tab broadcasts a "message" to all other tabs telling them to
 *    reload.
 *
 * 6. The "active" tab then reloads itself.
 *
 * Want to know more?
 * ------------------
 * + Service Worker update issues
 *   @see https://redfin.engineering/service-workers-break-the-browsers-refresh-button-by-default-here-s-why-56f9417694
 *
 * + Service Worker update solutions
 *   @see https://redfin.engineering/how-to-fix-the-refresh-button-when-using-service-workers-a8e27af6df68
 *
 * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 *
 * 2. Update Stale Session:
 * ========================
 *
 * Why:
 * ----
 * Some users (especially in Native Mobile contexts) can leave their EP session
 * running for long periods of time. This can cause MFE assets to become stale
 * as new deployments are missed.
 *
 * How:
 * ----
 * Updating a `remoteEntry.js` file is straightforward.
 *
 * 1. As a subtle way of refreshing the browser (and getting fresh assets), after
 *    a period of time we show the notification asking users to "update".
 *
 * 2. The users clicks "update" which starts the sequence to reload all Browser
 *    tabs (so that they reference a fresh `remoteEntry.js` file(s) when the App
 *    Shell initialises).
 *
 * 3. The "active" tab broadcasts a "message" to all other tabs telling them to
 *    reload.
 *
 * 4. The "active" tab then reloads itself.
 */
export const createApplicationUpdateNotification = (
    acceptClick: NotificationInteraction,
    declineClick: NotificationInteraction
): ApplicationNotification => {
    let notificationElement: HTMLElement;
    let notificationContext: NotificationContexts;

    const scaffold = `
  <div
    class="mfeAppShell-ServiceWorker mfeStack--toast"
    role="alert"
    aria-live="polite"
    >
    <svg
      class="mfeAppShell-ServiceWorker__icon"
      viewBox="0 0 24 24"
      aria-hidden="true">
      <path d="M4 12c0 4.4 3.6 8 8 8s8-3.6 8-8-3.6-8-8-8-8 3.6-8 8zm8-2h-2v2h1v3H9v2h6v-2h-2v-4c0-.6-.4-1-1-1zm0-3.2c-.7 0-1.2.6-1.2 1.2s.6 1.2 1.2 1.2 1.2-.6 1.2-1.2-.5-1.2-1.2-1.2zM22 12c0 5.5-4.5 10-10 10S2 17.5 2 12 6.5 2 12 2s10 4.5 10 10z"></path>
    </svg>

    A new version of EP is available

    <button
      id="mfeAppShellServiceWorkerUpdateButton"
      class="
        mfeAppShell-ServiceWorker__button
        mfeAppShell-ServiceWorker__button--update
      "
      type="button">
      Update
    </button>

    <button
      id="mfeAppShellServiceWorkerCloseButton"
      class="
        mfeAppShell-ServiceWorker__button
        mfeAppShell-ServiceWorker__button--close"
      type="button"
      aria-label="Close">

      <svg
        class="mfeAppShell-ServiceWorker__icon"
        viewBox="0 0 24 24"
        aria-hidden="true">
        <path d="M18.3 5.71a1 1 0 0 0-1.41 0L12 10.59 7.11 5.7A1 1 0 1 0 5.7 7.11L10.59 12 5.7 16.89a1 1 0 1 0 1.41 1.41L12 13.41l4.89 4.89a1 1 0 1 0 1.41-1.41L13.41 12l4.89-4.89a1 1 0 0 0 0-1.4z"></path>
      </svg>
    </button>
  </div>
  `;

    const handleAcceptClick = () => acceptClick({ context: notificationContext });

    const handleDeclineClick = () => {
        declineClick({ context: notificationContext });
        removeNotificationFromDom();
    };

    const toggleEventListeners = (intent: "addEventListener" | "removeEventListener") =>
        [
            ["#mfeAppShellServiceWorkerUpdateButton", handleAcceptClick],
            ["#mfeAppShellServiceWorkerCloseButton", handleDeclineClick],
        ].forEach(([selector, handler]: [string, () => void]) =>
            notificationElement.querySelector(selector)[intent]("click", handler)
        );

    const addEventListeners = () => toggleEventListeners("addEventListener");

    const removeEventListeners = () => toggleEventListeners("removeEventListener");

    const addNotificationToDom: AddNotification = ({ context }) => {
        if (!notificationElement) {
            notificationContext = context;
            notificationElement = document.createElement("div");
            // Disable rule as HTML is hardcoded and has no user input.
            // eslint-disable-next-line no-unsanitized/property
            notificationElement.innerHTML = scaffold;
            addEventListeners();
            document.body.insertBefore(notificationElement, document.body.firstChild);
        } else {
            // We can encounter a scenario where we want to inform the user of an update
            // when an existing notification is already in the DOM.
            //
            // We cannot simply ignore the "new" or replace the "old" notification, as
            // notifications are not all equal.
            //
            // In this situation, we need to create a notification that can accommodate
            // our two update scenarios. These scenarios could be...
            //
            // Update Service Worker:
            // ----------------------
            // 1. Override existing Service Worker
            // 2. Reload Browser Tabs
            //
            // Update Stale Session:
            // ---------------------
            // 1. Reload Browser Tabs
            //
            // Since the "Update Service Worker" does everything that the "Update Stale
            // Session" sequence does and more, it takes priority when multiple
            // notification contexts are considered.
            //
            // Below are the possible comparisons and their results.
            // -----------------------------------------------------
            //
            // | Old Notification | New Notification | Chosen Notification |
            // | ---------------- | ---------------- | ------------------- |
            // | Service Worker   | Service Worker   | Service Worker      |
            // | Service Worker   | Stale Session    | Service Worker      |
            // | Stale Session    | Service Worker   | Service Worker      |
            // | Stale Session    | Stale Session    | Stale Session       |
            const isAlreadyServiceWorkerContext = notificationContext === NotificationContexts.SERVICE_WORKER;
            notificationContext = isAlreadyServiceWorkerContext ? notificationContext : context;
        }
    };

    const removeNotificationFromDom = () => {
        if (notificationElement) {
            removeEventListeners();
            notificationElement.parentNode.removeChild(notificationElement);
            notificationElement = undefined;
        }
    };

    return {
        add: addNotificationToDom,
        remove: removeNotificationFromDom,
    };
};
